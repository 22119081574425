import MapActions from "../../contract/MapActions";
import BaseImplementation from "../BaseImplementation";
import ViewState from "../../types/map-actions/ViewState";
import ActionErrors from "../../contract/errors/ActionErrors";

class MActions extends BaseImplementation implements MapActions {

    static source: string;
    static viewState: ViewState = ViewState.UNKNOWN;

    setMapVisibleState(state: ViewState): void {
        const mapElement = document.getElementById('root');
        if (!mapElement) throw new Error(ActionErrors.ELEMENT_NOT_FOUND);
        MActions.viewState = state;
        mapElement.style.visibility = state
    }

    getMapViewState(): ViewState {
        return MActions.viewState;
    }

    toggleMapViewState(): ViewState {
        switch (MActions.viewState) {
            case ViewState.INVISIBLE:
                this.setMapVisibleState(ViewState.VISIBLE);
                return ViewState.VISIBLE;
            case ViewState.VISIBLE:
                this.setMapVisibleState(ViewState.INVISIBLE);
                return ViewState.INVISIBLE;
        }
    }

    // @ts-ignore
    setPlatform(source: string): void {
        if (source === "mobile-ios" && window.navigator.permissions) {
            // HACK: iOS 16.0's WKWebView defines `window.navigator.permissions`, but proceeds to
            //       throw a `NotSupportedError` upon query, causing GeolocateControl to not function
            //       at all. This hack catches such an errored permission query to then return a non-error
            //       value so that the control is drawn and can continue.
            let originalQuery = window.navigator.permissions.query;
            // @ts-ignore
            window.navigator.permissions.query = function() {
                // @ts-ignore
                return originalQuery.apply(this, arguments).catch(err => {
                    if (err.name === "NotSupportedError")
                        return { state: "ok" };
                    throw err;
                });
            };
        }

        MActions.source = source;
    }

    exportWindow(): void {

        // @ts-ignore
        window.setMapVisibleState = this.setMapVisibleState.bind(this);
        // @ts-ignore
        window.getMapViewState = this.getMapViewState.bind(this);
        // @ts-ignore
        window.toggleMapViewState = this.toggleMapViewState.bind(this);
        // @ts-ignore
        window.setPlatform = this.setPlatform.bind(this);
    }

}

export default MActions